<template>
  <CCard class="p-4">
    <CCardBody>
      <h1 class="login-title">{{ $t("Reset password") }}</h1>
      <p class="text-muted">{{ $t("Set your new password") }}</p>
      <CForm @submit.prevent="handleSubmit">
        <label class="login__label mb-20">
          <span>{{ $t("New password") }}</span>
          <div class="login__input">
            <CIcon name="password" size="custom" />
            <b-form-input
              v-model="password1"
              required
              type="password"
              autocomplete="password"
              name="password1"
              :placeholder="$t('New password')"
            ></b-form-input>
          </div>
        </label>
        <!-- <CInput
          :placeholder="$t('New password')"
          type="password"
          autocomplete="password"
          v-model="password1"
          name="password1"
          required
        >
          <template #prepend-content>
            <CIcon name="cil-lock-locked"/>
          </template>
        </CInput> -->
        <label class="login__label mb-20">
          <span>{{ $t("Repeat new password") }}</span>
          <div class="login__input">
            <CIcon name="password" size="custom" />
            <b-form-input
              v-model="password2"
              required
              type="password"
              autocomplete="password"
              name="password2"
              :placeholder="$t('Repeat new password')"
            ></b-form-input>
          </div>
        </label>
        <!-- <CInput
          class="my-2"
          :placeholder="$t('Repeat new password')"
          type="password"
          autocomplete="password"
          v-model="password2"
          name="password2"
          required
        >
          <template #prepend-content>
            <CIcon name="cil-lock-locked" />
          </template>
        </CInput> -->
        <CButton color="primary" class="px-4 cus-button" type="submit">
          {{ $t("Change password") }}
        </CButton>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { AuthService } from "@/services/auth";

export default {
  name: "ResetPassword",
  data: function () {
    return {
      password1: "",
      password2: "",
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.password1 || this.password1 !== this.password2) {
        return alert(this.$t("Passwords are not the same"));
      }
      const { res } = await AuthService.resetPassword({
        password: this.password1,
        token: this.$route.params.token,
      });
      if (res.status === 200) {
        alert(this.$t("auth.change_success"));
        return this.$router.push({ name: "Management Login" });
      }
      alert(this.$t("Something went wrong"));
    },
  },
};
</script>
